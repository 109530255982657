<template>

	<div style="width: 100%;">

		<iframe v-if="mode === 'form'" id="summernote_frame" ref="summernote_frame" frameborder="0" scrolling="no" :src="'/common/summernote/summernote_form.html'"
		        width="100%" height="0" style="width: 100%; overflow-y: auto;" @load="resizeFrame" @update-size="resizeFrame"></iframe>

	</div>

</template>

<script>

import $ from 'jquery'

export default {
	name: "summernoteForm",
	props: {
		mode: String,
		html: String,
	},
	data () {

		//alert(this.mode);

		return {

		}
	},
	created() {
	},
	mounted() {
		if ( this.html !== '' ) {
			this.setHtml()
		}
	},
	methods: {

		resizeFrame () {

			this.$nextTick(() => {
				/*alert("resizeFrame");
				console.log("resizeFrame")*/
				const _dom = document.querySelector('#summernote_frame')
				//document.querySelector("#content_frame")

				_dom.style.height = '0px';
				_dom.style.overflowY = "hidden";
				_dom.style.height = (_dom.contentWindow.document.documentElement.scrollHeight + 10) + 'px';
			})
		},

		getHtml() {
			return $('#summernote_frame').get(0).contentWindow.getHtml();
		},

		setHtml() {

			const self = this
			if ( $('#summernote_frame').get(0).contentWindow.setHtml !== undefined ) {

				// console.log( $('#summernote_frame').contents().find('#o_contentHtml') );
				// $('#summernote_frame').contents().find('#o_contentHtml').text(this.html)
				$('#summernote_frame').get(0).contentWindow.setHtml(this.html);
				this.$nextTick(() => {
					this.resizeFrame()
				})
				this.resizeFrame()

			} else {
				setTimeout(() => {
					console.log("HTML 입력 재시도")
					self.setHtml()
				}, 100)
			}
		}


	},
	watch: {

		'html': function (newVal) {
			if ( newVal !== undefined ) {
				this.setHtml();
			}
		}

	}
}
</script>

<style scoped>

</style>