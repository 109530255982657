<template>
    <main id="formBasic" class="container">
        <div class="content">
            <div class="content_head">
                <div class="inner">
                    <div class="breadcrumb">
                        <ul>
                            <li><a href="#" class="ico_home" title="Home"></a></li>
                            <li>{{boardInfoVO.boardName}}</li>
                        </ul>
                    </div>
                    <h2 class="page_title">{{boardInfoVO.boardName}}</h2>
                </div>
            </div>
            <div class="content_body">
                <div class="inner">
                    <div class="board_write">
                        <div class="write_head">
                            <h3 class="tit"><input v-model="boardVO.title" type="text" class="inp" style="width: 100%" placeholder="제목을 입력해 주세요."></h3>
                        </div>
                        <div class="write_body" style="padding-top: 0">
                            <summernote-frame ref="summernote" mode="form" :html="boardVO.contentHtml"></summernote-frame>
                        </div>
                        <p class="write_info">
                            WAPU 운영 정책, 비방, 욕설 등 게시할 수 없다고 판단되는 경우 사전 동의없이 삭제될 수 있습니다.
                        </p>
                        <div class="button_area">
                            <button type="button" class="btn_cancle btn_black" @click="boardBack">취소</button>
                            <button type="button" class="btn_write btn_primary" @click="setBoard">등록</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import SummernoteFrame from "@/components/display/summernoteFrame";



    export default {
        name: "formBasic",
        components: {SummernoteFrame},
        data(){
            var params = this.pageUtils.Params()

            params.idx  =  this.$route.query.idx || 0

            return {
                boardVO: new this.BoardVO(this.getSessionProc()),
                boardInfoVO: {},
                params,
            }
        },
        created() {
            if (this.params.idx > 0) {
                this.getBoardOne();
            }
        },
        mounted() {
            this.initForm()
        },
        methods:{

            async initForm(){
                this.boardInfoVO = await this.getBoardInfo('cms_board')

            },
            boardBack(){
              history.back()
            },
            setBoard(e) {

                //해당 화면은 작성 화면
                //view는 별도로 존재

                e.preventDefault()



                this.boardVO.boardKey = 'cms_board'
                // this.boardVO.cateCode = this.boardInfoVO.cateCode
				this.boardVO.contentHtml = this.$refs.summernote.getHtml()
                //
                // this.boardVO.fileIdxs = this.fileIdxs.join(",")
                // this.boardVO.thumbIdx = this.thumbIdx
                // console.log( this.boardVO )
                //
                // if ( this.stringUtils.isEmpty( this.boardVO.boardKey ) ) {
                //     this.swalUtils.gritter("error", "정상적인 접근이 아닙니다. 다시 시도해주세요.", "error", 800)
                //         .then(() => {
                //             history.back();
                //         })
                //     return
                // }

                this.$eventBus.$emit("startProgress")
                console.log(this.boardVO)
                this.axiosCaller.post( this, this.APIs.BOARDS + "/setBoardOne", this.boardVO, (res) => {
                    this.$eventBus.$emit("doneProgress")
                    const result = res.data
                    console.log( result )
                    if ( result.status === "ok" ) {
                        this.swalUtils.gritter("Success", "저장되었습니다", "success", 800)
                            .then((res) => {  // eslint-disable-line no-unused-vars
                                location.href="/review/cms_board"
                            })
                    }
                })

            },


            getBoardOne() {

                this.$eventBus.$emit("startProgress", 1)
                this.axiosCaller.get(this, this.APIs.BOARDS + "/getBoardOne/" + this.params.idx, {}, (res) => {
                    var result = res.data
                    this.boardVO = result.boardVO
                    this.$eventBus.$emit("doneProgress", 1)

                    this.$nextTick(() => {
                        this.webUtils.scrollTo("#app")
                    })
                })
            },
        }
    }
</script>

<style scoped>

</style>